<!--
 * @Author: 王昌浩
 * @Date: 2020-07-30 14:57:23
 * @LastEditTime: 2021-03-05 11:06:44
 * @LastEditors: Please set LastEditors
 * @Description: 设备工单
 * @FilePath: \trunk\src\pages\equipmentState\aomOrder\aomOrder.vue
-->
<template>
  <div class="page1">
    <div class="content">
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Berth_number')">
                <el-input
                  placeholder="泊位号"
                  maxlength="30"
                  v-model="formInline.berthCode"
                ></el-input>
              </el-form-item>
              <el-form-item label="所在道路">
                <my-component
                  ref="parkInput"
                  @valueChange="completeValue"
                  slaveRelations="0,1"
                  :commercialStatus="'2,3'"
                >
                </my-component>
                <!-- <el-select
              v-model.trim="formInline.parentId"
              filterable
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="value.parkName"
                :value="value.parkId"
                :key="value.parkId"
                v-for="value in parkParent"
              ></el-option>
            </el-select> -->
              </el-form-item>
              <el-form-item :label="$t('searchModule.state')">
                <el-select v-model.trim="formInline.type" filterable placeholder="请选择">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="完工" value="3"></el-option>
                  <el-option label="派工" value="1"></el-option>
                  <el-option label="搁置" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Report_time')">
                <el-date-picker
                  v-model="datetimerange"
                  type="datetimerange"
                  range-separator="至"
                  :default-time="['00:00:00', '23:59:59']"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table :data="tableData" style="width: 100%; font-size: 12px">
          <el-table-column label="事件编号" width="80" align="center" prop="orderCode">
          </el-table-column>
          <el-table-column
            width="110"
            label="工单设备类型"
            align="center"
            :resizable="false"
            prop="equipType"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            :label="$t('searchModule.Berth_number')"
            align="center"
            width="300px"
            :resizable="false"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                :content="scope.row.berthCodes"
                placement="top"
              >
                <span class="berthCode">{{ scope.row.berthCodes | showBerthCode }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            label="所在路段"
            width="90"
            show-overflow-tooltip
            align="center"
            prop="parkName"
          >
          </el-table-column>
          <el-table-column
            label="所在道路"
            align="center"
            prop="parentParkName"
            width="90"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column :label="$t('searchModule.state')" prop="state" align="center" width="90">
            <template slot-scope="scope">
              <span>{{ scope.row.state }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态变更时间" align="center" prop="updatedTime" width="170">
          </el-table-column>
          <el-table-column label="派工时间" width="170" align="center" prop="reportTime">
          </el-table-column>
          <el-table-column align="center" label="查看详情">
            <template slot-scope="scope">
              <el-button type="text" style="padding: 0" @click="handleClick(scope.row)">
                查看详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagerWrapper">
          <div class="block" style="text-align: right">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!--分页器-->
    </div>
  </div>
</template>

<script>
import myComponent from "@/components/autocomplete/myautoComponent";
import { dateFormat } from "@/common/js/public";
export default {
  components: { myComponent },
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setTime(date1.getTime() - 3600 * 1000 * 24 * 30);
    let startTime = date1;
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      datetimerange: [startTime, endTime],
      formInline: {
        berthCode: "",
        parentId: "",
        parkName: "",
        type: "",
      },
      tableData: [],
      parkParent: [], // 父车场
      page: 1,
      pageSize: 15,
      total: 0,
      loading: false,
    };
  },
  filters: {
    showBerthCode(a) {
      if (a) {
        return a.length > 30 ? a.substring(0, 30) + "..." : a;
      } else {
        return a;
      }
    },
  },
  // 方法集合
  methods: {
    completeValue(item) {
      if (item) {
        this.formInline.parentId = item.parkId;
        this.formInline.parkName = item.parkName;
      } else {
        this.formInline.parentId = "";
        this.formInline.parkName = "";
      }
    },
    searchData() {
      this.tableData = [];
      this.total = 0;
      let url = "/acb/2.0/aomEquOrder/page";
      this.$axios
        .get(url, {
          data: {
            berthCode: this.formInline.berthCode,
            parentParkId: this.formInline.parentId,
            status: this.formInline.type,
            startTime: dateFormat(this.datetimerange[0]),
            endTime: dateFormat(this.datetimerange[1]),
            pageNum: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // getParentPark() {
    //   let url = "/acb/2.0/park/getParentParks";
    //   this.$axios
    //     .get(url, {
    //       data: {
    //         slaveRelations: "0,1",
    //       },
    //     })
    //     .then((res) => {
    //       if (res.state == 0) {
    //         this.parkParent = res.value;
    //       } else {
    //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
    //           confirmButtonText: this.$t('pop_up.Determine'),
    //         });
    //       }
    //     });
    // },
    handleClick(v) {
      this.$router.push({
        path: "/aomOrderDetail",
        query: {
          orderId: v.orderId,
        },
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
  },
  created() {},
  mounted() {
    // this.getParentPark();
    this.searchData();
  },
};
</script>
<style lang="stylus" scoped>
.el-icon-date-custom{
  position: relative
  left: -26px;
}
// @import url(); 引入公共css类
.berthCode
  display inline-block
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;

.pagerWrapper
    text-align right
    margin-top 18px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
</style>
